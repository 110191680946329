import styled from "styled-components"

const MainContainer = styled.main`
  padding: 22vh 0 0 0;
  overflow: hidden;
  background: #ffffff;
  position: relative;
  @media only screen and (max-width: 800px) {
    ${props => props.paddingTop && "padding-top: 10vh;"}
  }
`

const Container = styled.div`
  margin: 0 auto;
  padding: 0 6vw;
  position: relative;
  @media only screen and (max-width: 800px) {
    ${props => props.paddingTop && "padding-top: 10vh;"}
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: ${props => (props.full ? "100%" : "95%")};
  ${props => props.center && "align-items: center;"}
  @media only screen and (max-width: 800px) {
    width: ${props => (props.full ? "100%" : "97%")};
  }
`

export { MainContainer, Container, Row }
