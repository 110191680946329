import React, { useEffect, useContext } from "react"
import styled from "styled-components"
import Img from "gatsby-image"

import Layout from "../components/layout/index.js"
import SEO from "../components/seo"
import Card from "../components/Card"
import BackgroundBlob from "../components/Blob"

import { BlueText, Title, Text, Button, SectionTitle } from "../elements/Text"
import { MainContainer, Container } from "../elements/Container"
import { family, size } from "../elements/font"
import colors from "../elements/color"
import AppContext from "../components/Context"

const TeamContainer = styled.div`
  position: relative;
  padding-left: 14vw;
  padding-right: 8vw;
  width: 93%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  @media only screen and (max-width: 800px) {
    padding: 0 6vw;
    width: 100%;
  }
`

const MemberCadre = styled.a`
  width: 22.5%;
  border: 2px solid rgba(216, 210, 210, 0.7);
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  color: black;
  text-decoration: none;
  &::after {
    content: attr(data-mail) "@novlini.com";
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    background: rgba(135, 135, 135, 0.8);
    color: white;
    text-decoration: underline;
    font-family: ${family.Alata};
    font-size: 1.3vw;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  &:hover::after {
    opacity: 1;
  }
  @media only screen and (max-width: 800px) {
    width: 30%;
    margin-top: 20px;
    &::after {
      font-size: 12px;
      line-height: 20px;
      padding: 0 2vw;
      padding-top: 40%;
      display: block;
      text-align: center;
      vertical-align: middle;
    }
  }
`

const ImageMember = styled.div`
  width: 100%;
  height: 70px;
  margin-top: 5px;
  display: flex;
  justify-content: center;
  @media only screen and (max-width: 800px) {
    height: 60px;
  }
`

const Name = styled.p`
  width: 100%;
  text-align: center;
  font-size: 0.9rem;
  margin-top: 10px;
  line-height: 10px;
  margin-bottom: 0;
  font-family: ${family.Alata};
  @media only screen and (max-width: 800px) {
    font-size: 18px;
  }
`

const Post = styled.p`
  width: 100%;
  text-align: center;
  font-size: 0.9vw;
  line-height: 0.9vw;
  margin-bottom: 5px;
  margin-top: 5px;
  font-family: ${family.NATS};
  @media only screen and (max-width: 800px) {
    font-size: 10px;
    line-height: 10px;
    margin-top: 10px;
    padding: 0 10px;
  }
`

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  @media only screen and (max-width: 800px) {
    margin-top: 40px;
    & > button {
      padding: 10px 38px;
      font-size: 1rem;
    }
  }
  @media screen and (max-width: 900px) and (max-height: 430px) {
    margin-bottom: 20px;
  }
`

const AbsoluteText = styled.p`
  position: absolute;
  font-family: ${family.Archivo};
  font-size: ${size.XL};
  top: 0;
  right: 0.5vw;
  transform-origin: right;
  transform: rotate(90deg) translateX(100%);
  @media only screen and (max-width: 800px) {
    position: relative;
    font-size: 20px;
    margin-top: 40px;
    margin-bottom: 0;
    right: 0;
    transform: rotate(0deg) translateX(0);
    width: 100%;
  }
`

const SvgContainer = styled.svg`
  width: 100%;
  margin-top: 5px;
  height: 70px;
  overflow: visible !important;
  @media only screen and (max-width: 800px) {
    height: 60px;
    width: 50%;
  }

  @media screen and (max-width: 900px) and (max-height: 430px) {
    width: 40%;
  }
`

const FixedContainer = styled.div`
  height: 180vh;
  width: 100vw;
  position: relative;

  @media screen and (min-width: 600px) and (max-width: 800px) {
    height: 150vh;
  }

  @media screen and (max-width: 900px) and (max-height: 430px) {
    height: auto;
  }

  @media only screen and (min-width: 1000px) and (max-width: 1366px) {
    height: 170vh;
  }
`

const ContainerAnimScroll = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  &.scroll {
    position: fixed;
  }
  &.stop-scroll {
    position: absolute;
    top: 35%;
  }
  @media only screen and (max-width: 900px) {
    height: auto;
  }
`

const WorkTitleContainer = styled(Container)`
  text-align: right;
  padding-right: 8vw;
  align-self: flex-end;
  margin-right: 0;
  @media only screen and (max-width: 800px) {
    margin-top: 10vh;
    text-align: left;
  }
`

const WorkContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 8vw 0 6vw;
  margin-top: 5vh;
  @media only screen and (max-width: 800px) {
    padding: 0 5vw 0 5vw;
    flex-direction: column;
    align-items: center;
  }
`

const BigContainer = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  z-index: 10;
  padding-left: 10px;
`

const CardsContainer = styled.div`
  width: 65%;
  height: 55vh;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  @media only screen and (max-width: 800px) {
    width: 100%;
    height: auto;
  }

  @media screen and (max-width: 900px) and (max-height: 430px) {
    height: auto;
    margin-bottom: 20px;
  }
`

const CardInner = styled.div`
  width: 100;
  height: auto;
`

const BigTitle = styled.h2`
  font-size: 5vw;
  font-family: ${family.Archivo};
  color: ${colors.darkBlue};
  letter-spacing: 6px;
  font-weight: 500;
`

const LinkBack = styled.a`
  ${props =>
    props.little
      ? `font-size: ${size.XXS}; padding: 0px 20px;`
      : `font-size: ${size.XS}; padding: 2px 50px;`}
  color: ${colors.darkBlue};
  border: solid 2px ${colors.darkBlue};
  border-radius: 5px;
  font-family: ${family.Alata};
  letter-spacing: 0.1em;
  position: relative;
  background: ${props => (props.background ? props.background : "white")};
  margin-top: 40px;
  z-index: 50;
  transition: 0.3s ease;
  text-decoration: none;
  &:hover {
    color: white;
    background: ${colors.darkBlue};
  }
  @media only screen and (max-width: 800px) {
    margin-top: 0;
    background: ${props =>
      props.noBackground ? "transparent" : props.background};
  }

  @media screen and (max-width: 900px) and (max-height: 430px) {
    &.join {
      position: absolute;
      bottom: 20px;
      right: 5%;
    }
  }
`

const BigButton = styled(LinkBack)`
  font-size: 1rem;
  padding: 8px 38px;
  width: fit-content;
  margin-top: 30px;
  @media only screen and (max-width: 800px) {
    margin: 70px 0;
    background: transparent;
  }
`

const Alata = styled.span`
  font-family: ${family.Alata};
  font-size: 110%;
`

const PageTalents = ({ data, path }) => {
  const { isMobile: mobile, isTablet } = useContext(AppContext)
  const prismic = data.prismicPageTalents.data

  const isMobile = mobile || (isTablet && window.innerWidth <= 800)

  const handleScroll = () => {
    const $fixedDivContainer = document.querySelector("#fixedDiv")
    const $stickyContainer = document.querySelector("#talentsSticky")
    const $cardInner = document.querySelector("#cardInner")
    const verticalPos = window.pageYOffset - ($cardInner.offsetHeight + 190)
    const toScroll = $cardInner.getBoundingClientRect().height / 1.8
    const $footer = document.querySelector("#footerContainer")
    $footer.classList.remove("disappear")

    if (
      $fixedDivContainer.getBoundingClientRect().top < 0 &&
      !$stickyContainer.classList.contains("scroll") &&
      verticalPos < toScroll
    ) {
      $stickyContainer.classList.add("scroll")
    } else if (
      $fixedDivContainer.getBoundingClientRect().top < 0 &&
      verticalPos < toScroll
    ) {
      $cardInner.style.transform = `translateY(-${verticalPos}px)`
      if ($stickyContainer.classList.contains("stop-scroll")) {
        $stickyContainer.classList.remove("stop-scroll")
      }
    } else if (
      $stickyContainer.classList.contains("scroll") &&
      $fixedDivContainer.getBoundingClientRect().top > 0
    ) {
      $stickyContainer.classList.remove("scroll")
      $cardInner.style.transform = `translateY(0)`
    } else if (
      $fixedDivContainer.getBoundingClientRect().top < 0 &&
      verticalPos > toScroll
    ) {
      $stickyContainer.classList.remove("scroll")
      $stickyContainer.classList.add("stop-scroll")
      $cardInner.style.transform = `translateY(-${toScroll}px)`
    }
  }

  useEffect(() => {
    if (!isMobile) {
      const $footer = document.querySelector("#footerContainer")
      $footer.classList.add("disappear")
      window.addEventListener("scroll", handleScroll)
      return () => {
        window.removeEventListener("scroll", handleScroll)
      }
    } else {
      const $footer = document.querySelector("#footerContainer")
      $footer.classList.add("disappear")
      setTimeout(() => {
        $footer.classList.remove("disappear")
      }, 500)
    }
  })

  return (
    <Layout
      pageValueMenu={2}
      slide={true}
      headerString={["Métiers", "Carrières", ""]}
      hasScrolled={true}
      footerText={prismic.footer_quote[0].text}
    >
      <SEO
        title={prismic.page_title[0].text}
        description={prismic.page_description[0].text}
        image={prismic.og_image.url}
        location={path}
      />
      <MainContainer paddingTop>
        {isMobile ? (
          <>
            <svg
              className="bulle-mobile-droite"
              style={{ position: "absolute", right: "0", top: "5%" }}
              width="344"
              height="668"
              viewBox="0 0 344 668"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M245.896 1.07582C296.88 -3.06819 354.077 3.27806 390.664 46.5487C426.134 88.4979 409.868 160.565 428.026 216.475C448.119 278.34 511.945 323.806 501.944 389.184C492.039 453.933 422.807 472.143 382.225 515.885C335.313 566.451 307.721 652.114 245.896 665.173C182.351 678.595 110.139 643.141 70.0787 581.817C31.9875 523.508 62.7779 438.228 50.0906 365.439C39.4425 304.349 -10.9648 252.57 2.16679 192.195C15.3726 131.48 71.0968 103.079 114.569 68.99C155.536 36.8656 197.224 5.03194 245.896 1.07582Z"
                fill="#F5FAFF"
              />
            </svg>
            <svg
              className="bulle-mobile-gauche"
              style={{ position: "absolute", left: "0", top: "35%" }}
              width="295"
              height="668"
              viewBox="0 0 295 668"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M37.8962 1.07582C88.8799 -3.06819 146.077 3.27806 182.664 46.5487C218.134 88.4979 201.868 160.565 220.026 216.475C240.119 278.34 303.945 323.806 293.944 389.184C284.039 453.933 214.807 472.143 174.225 515.885C127.313 566.451 99.7215 652.114 37.8962 665.173C-25.6489 678.595 -97.8605 643.141 -137.921 581.817C-176.013 523.508 -145.222 438.228 -157.909 365.439C-168.557 304.349 -218.965 252.57 -205.833 192.195C-192.627 131.48 -136.903 103.079 -93.4311 68.99C-52.4636 36.8656 -10.776 5.03194 37.8962 1.07582Z"
                fill="#F5FAFF"
              />
            </svg>
          </>
        ) : (
          <BackgroundBlob />
        )}
        <Container paddingTop>
          {isMobile && (
            <SectionTitle>
              01.
              <br />
              {prismic.mobile_section_title[0].text}
            </SectionTitle>
          )}
          <Title>
            {prismic.first_part_title_black[0].text}{" "}
            <BlueText>
              {prismic.first_part_title_blue[0].text}
              {isMobile && <br />}
            </BlueText>{" "}
            {prismic.first_part_title_black_1[0].text}
          </Title>
          <Text>
            {prismic.first_part_text.map((subtitle, index) => (
              <span key={index}>
                {subtitle.text.includes("novlini") ? (
                  <>
                    {subtitle.text.split("novlini").map((item, i) => (
                      <span key={i}>
                        {item}
                        {i === 0 && <Alata>novlini</Alata>}
                      </span>
                    ))}
                  </>
                ) : (
                  subtitle.text
                )}
                {!isMobile && <br />}
                {isMobile && index === 0 && (
                  <>
                    <br />
                    <br />
                  </>
                )}
              </span>
            ))}
          </Text>
        </Container>
        <TeamContainer className="changeHeaderString">
          <MemberCadre
            className="click"
            className="click"
            onClick={e => isMobile && e.preventDefault()}
            href={
              isMobile
                ? "#"
                : `mailto:${prismic.talents[0].name[0].text}@novlini.com`
            }
            data-mail={prismic.talents[0].name[0].text}
          >
            <SvgContainer
              width="78"
              height="90"
              viewBox="0 0 78 93"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.2324 15.3418V43.9183C19.2324 56.7599 29.9204 67.1681 43.1074 67.1681C56.2943 67.1681 66.9823 56.7599 66.9823 43.9183V16.1478L19.2324 15.3418Z"
                fill="#F3D2A2"
              />
              <path
                d="M32.4961 56.8311H53.7183C53.7183 56.8311 51.0655 61.9977 43.1072 61.9977C35.1489 61.9977 32.4961 56.8311 32.4961 56.8311Z"
                fill="#C1694F"
              />
              <path
                d="M32.5024 41.331C31.0381 41.331 29.8496 40.1737 29.8496 38.7477V36.1644C29.8496 34.7384 31.0381 33.5811 32.5024 33.5811C33.9667 33.5811 35.1552 34.7384 35.1552 36.1644V38.7477C35.1552 40.1737 33.9667 41.331 32.5024 41.331ZM53.7246 41.331C52.2602 41.331 51.0718 40.1737 51.0718 38.7477V36.1644C51.0718 34.7384 52.2602 33.5811 53.7246 33.5811C55.1889 33.5811 56.3773 34.7384 56.3773 36.1644V38.7477C56.3773 40.1737 55.1889 41.331 53.7246 41.331Z"
                fill="#662113"
              />
              <path
                d="M45.7618 50.3729H40.4563C39.7241 50.3729 39.1299 49.7942 39.1299 49.0812C39.1299 48.3682 39.7241 47.7896 40.4563 47.7896H45.7618C46.494 47.7896 47.0882 48.3682 47.0882 49.0812C47.0882 49.7942 46.494 50.3729 45.7618 50.3729Z"
                fill="#C1694F"
              />
              <path
                d="M24.1338 35.4294C24.1338 39.511 22.0116 42.8203 19.3906 42.8203C16.7697 42.8203 14.6475 39.511 14.6475 35.4294C14.6475 31.3478 16.7723 28.0386 19.3906 28.0386C22.0089 28.0386 24.1338 31.3478 24.1338 35.4294ZM71.5706 35.4294C71.5706 39.511 69.4484 42.8203 66.8275 42.8203C64.2065 42.8203 62.0843 39.511 62.0843 35.4294C62.0843 31.3478 64.2065 28.0386 66.8275 28.0386C69.4484 28.0386 71.5706 31.3478 71.5706 35.4294Z"
                fill="#F3D2A2"
              />
              <path
                d="M43.1123 2.12598C24.4925 2.12598 16.2158 14.2262 16.2158 24.3114C16.2158 34.3941 19.7785 37.1918 20.3541 34.3941C22.4233 24.3114 28.6308 21.2166 28.6308 21.2166C41.0458 31.3018 38.9766 23.2342 38.9766 23.2342C51.3889 35.3344 45.1841 23.2342 45.1841 23.2342C49.3224 27.2693 61.7347 27.2693 61.7347 27.2693C61.7347 27.2693 63.8039 30.3641 65.8704 34.3966C67.9396 38.4318 70.0087 34.3966 70.0087 24.314C70.0087 14.2262 59.6629 2.12598 43.1123 2.12598Z"
                fill="#E8AD64"
              />
              <path
                className="main-anim"
                d="M28.3115 7.58249C26.7437 6.57759 24.6321 7.00383 23.6002 8.53057L21.8892 11.0674V5.1671C21.8892 2.31255 19.5123 0.000488281 16.5836 0.000488281H5.97253C3.04387 0.000488281 0.666992 2.31513 0.666992 5.1671V25.8336C0.666992 25.8336 0.666992 28.4169 3.31976 28.4169H19.2364L21.8892 25.8336V23.1392L29.2877 12.1704C30.3197 10.6411 29.8846 8.5874 28.3115 7.58249Z"
                fill="#F3D2A2"
              />
              <path
                className="main-anim"
                d="M5.9668 0H8.61957V12.9165H5.9668V0ZM13.9251 0H16.5779V12.9165H13.9251V0Z"
                fill="#D2A077"
              />
              <path
                d="M66.9853 69.7477H19.2354L21.8882 25.8315H0.666016V69.7477C0.666016 69.7477 0.666016 92.9975 21.8882 92.9975H77.5964V80.081C77.5964 74.3744 72.8453 69.7477 66.9853 69.7477Z"
                fill="#5AA4DE"
              />
              <path
                d="M64.334 82.6641H66.9868V92.9973H64.334V82.6641Z"
                fill="#2A6797"
              />
              <path
                d="M35.1514 61.9995V72.3327C35.1514 76.6133 38.714 80.0827 43.1097 80.0827C47.5053 80.0827 51.068 76.6133 51.068 72.3327V61.9995H35.1514Z"
                fill="#F3D2A2"
              />
            </SvgContainer>
            <Name>{prismic.talents[0].name[0].text}</Name>
            <Post>{prismic.talents[0].role[0].text}</Post>
          </MemberCadre>
          <MemberCadre
            className="click"
            onClick={e => isMobile && e.preventDefault()}
            href={
              isMobile
                ? "#"
                : `mailto:${prismic.talents[1].name[0].text}@novlini.com`
            }
            data-mail={prismic.talents[1].name[0].text}
          >
            <ImageMember>
              <Img
                loading="eager"
                fixed={[
                  data.maximeMobile.childImageSharp.fixed,
                  {
                    ...data.maxime.childImageSharp.fixed,
                    media: `(min-width: 768px)`,
                  },
                ]}
              />
            </ImageMember>
            <Name>{prismic.talents[1].name[0].text}</Name>
            <Post>{prismic.talents[1].role[0].text}</Post>
          </MemberCadre>
          <MemberCadre
            className="click"
            onClick={e => isMobile && e.preventDefault()}
            href={
              isMobile
                ? "#"
                : `mailto:${prismic.talents[2].name[0].text}@novlini.com`
            }
            data-mail={prismic.talents[2].name[0].text}
          >
            <ImageMember>
              <Img
                loading="eager"
                fixed={[
                  data.pernillaMobile.childImageSharp.fixed,
                  {
                    ...data.pernilla.childImageSharp.fixed,
                    media: `(min-width: 768px)`,
                  },
                ]}
              />
            </ImageMember>
            <Name>{prismic.talents[2].name[0].text}</Name>
            <Post>{prismic.talents[2].role[0].text}</Post>
          </MemberCadre>
          <MemberCadre
            className="click"
            onClick={e => isMobile && e.preventDefault()}
            href={
              isMobile
                ? "#"
                : `mailto:${prismic.talents[3].name[0].text}@novlini.com`
            }
            data-mail={prismic.talents[3].name[0].text}
          >
            <ImageMember>
              <Img
                loading="eager"
                fixed={[
                  data.vincentMobile.childImageSharp.fixed,
                  {
                    ...data.vincent.childImageSharp.fixed,
                    media: `(min-width: 768px)`,
                  },
                ]}
              />
            </ImageMember>
            <Name>{prismic.talents[3].name[0].text}</Name>
            <Post>{prismic.talents[3].role[0].text}</Post>
          </MemberCadre>
          <MemberCadre
            className="click"
            onClick={e => isMobile && e.preventDefault()}
            href={
              isMobile
                ? "#"
                : `mailto:${prismic.talents[4].name[0].text}@novlini.com`
            }
            data-mail={prismic.talents[4].name[0].text}
          >
            <ImageMember>
              <Img
                loading="eager"
                fixed={[
                  data.regisMobile.childImageSharp.fixed,
                  {
                    ...data.regis.childImageSharp.fixed,
                    media: `(min-width: 768px)`,
                  },
                ]}
              />
            </ImageMember>
            <Name>{prismic.talents[4].name[0].text}</Name>
            <Post>{prismic.talents[4].role[0].text}</Post>
          </MemberCadre>
          <MemberCadre
            className="click"
            onClick={e => isMobile && e.preventDefault()}
            href={
              isMobile
                ? "#"
                : `mailto:${prismic.talents[5].name[0].text}@novlini.com`
            }
            data-mail={prismic.talents[5].name[0].text}
          >
            <ImageMember>
              <Img
                loading="eager"
                fixed={[
                  data.hamzaMobile.childImageSharp.fixed,
                  {
                    ...data.hamza.childImageSharp.fixed,
                    media: `(min-width: 768px)`,
                  },
                ]}
              />
            </ImageMember>
            <Name>{prismic.talents[5].name[0].text}</Name>
            <Post>{prismic.talents[5].role[0].text}</Post>
          </MemberCadre>
          <MemberCadre
            className="click"
            onClick={e => isMobile && e.preventDefault()}
            href={
              isMobile
                ? "#"
                : `mailto:${prismic.talents[6].name[0].text}@novlini.com`
            }
            data-mail={prismic.talents[6].name[0].text}
          >
            <ImageMember>
              <Img
                loading="eager"
                fixed={[
                  data.vanilleMobile.childImageSharp.fixed,
                  {
                    ...data.vanille.childImageSharp.fixed,
                    media: `(min-width: 768px)`,
                  },
                ]}
              />
            </ImageMember>
            <Name>{prismic.talents[6].name[0].text}</Name>
            <Post>{prismic.talents[6].role[0].text}</Post>
          </MemberCadre>
          <MemberCadre
            className="click"
            onClick={e => isMobile && e.preventDefault()}
            href={
              isMobile
                ? "#"
                : `mailto:${prismic.talents[7].name[0].text}@novlini.com`
            }
            data-mail={prismic.talents[7].name[0].text}
          >
            <ImageMember>
              <Img
                loading="eager"
                fixed={[
                  data.margueriteMobile.childImageSharp.fixed,
                  {
                    ...data.marguerite.childImageSharp.fixed,
                    media: `(min-width: 768px)`,
                  },
                ]}
              />
            </ImageMember>
            <Name>{prismic.talents[7].name[0].text}</Name>
            <Post>{prismic.talents[7].role[0].text}</Post>
          </MemberCadre>
          <MemberCadre
            className="click"
            onClick={e => isMobile && e.preventDefault()}
            href={
              isMobile
                ? "#"
                : `mailto:${prismic.talents[8].name[0].text}@novlini.com`
            }
            data-mail={prismic.talents[8].name[0].text}
          >
            <ImageMember>
              <Img
                loading="eager"
                fixed={[
                  data.clotaireMobile.childImageSharp.fixed,
                  {
                    ...data.clotaire.childImageSharp.fixed,
                    media: `(min-width: 768px)`,
                  },
                ]}
              />
            </ImageMember>
            <Name>{prismic.talents[8].name[0].text}</Name>
            <Post>{prismic.talents[8].role[0].text}</Post>
          </MemberCadre>
          <MemberCadre
            className="click"
            onClick={e => isMobile && e.preventDefault()}
            href={
              isMobile
                ? "#"
                : `mailto:${prismic.talents[9].name[0].text}@novlini.com`
            }
            data-mail={prismic.talents[9].name[0].text}
          >
            <ImageMember>
              <Img
                loading="eager"
                fixed={[
                  data.emmaMobile.childImageSharp.fixed,
                  {
                    ...data.emma.childImageSharp.fixed,
                    media: `(min-width: 768px)`,
                  },
                ]}
              />
            </ImageMember>
            <Name>{prismic.talents[9].name[0].text}</Name>
            <Post>{prismic.talents[9].role[0].text}</Post>
          </MemberCadre>
          <MemberCadre
            className="click"
            onClick={e => isMobile && e.preventDefault()}
            href={
              isMobile
                ? "#"
                : `mailto:${prismic.talents[10].name[0].text}@novlini.com`
            }
            data-mail={prismic.talents[10].name[0].text}
          >
            <ImageMember>
              <Img
                loading="eager"
                fixed={[
                  data.wissalMobile.childImageSharp.fixed,
                  {
                    ...data.wissal.childImageSharp.fixed,
                    media: `(min-width: 768px)`,
                  },
                ]}
              />
            </ImageMember>
            <Name>{prismic.talents[10].name[0].text}</Name>
            <Post>{prismic.talents[10].role[0].text}</Post>
          </MemberCadre>
          <MemberCadre
            className="click"
            onClick={e => isMobile && e.preventDefault()}
            href={isMobile ? "#" : "mailto:m@novlini.com"}
            data-mail="m"
          >
            <SvgContainer
              viewBox="0 0 78 95"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M77.7906 94.8332V92.2159C77.7906 83.5449 70.8725 76.5122 62.3426 76.5122H15.9987C7.46889 76.5122 0.550781 83.5449 0.550781 92.2159V94.8332H77.7906Z"
                fill="#66757F"
              />
              <path
                d="M23.7197 72.54C28.0246 76.6622 33.3644 79.1303 39.1677 79.1303C44.971 79.1303 50.3082 76.6596 54.6156 72.54V68.6611H23.7197V72.54Z"
                fill="#EF9645"
              />
              <path
                d="M61.6969 54.0938C64.7556 54.6381 68.44 54.4314 67.9173 56.4938C66.684 61.3463 61.2129 59.5482 60.2474 59.6031C59.2819 59.6555 61.6969 54.0938 61.6969 54.0938Z"
                fill="#66757F"
              />
              <path
                d="M62.506 54.645C67.3334 55.2993 69.0482 55.7338 65.508 57.1524C63.5204 57.9507 59.733 69.7232 56.9318 67.9304C54.1332 66.1375 62.506 54.645 62.506 54.645Z"
                fill="#292F33"
              />
              <path
                d="M16.656 54.0938C13.5973 54.6381 9.91301 54.4314 10.4357 56.4938C11.6689 61.3463 17.1401 59.5482 18.1056 59.6031C19.0711 59.6555 16.656 54.0938 16.656 54.0938Z"
                fill="#66757F"
              />
              <path
                d="M15.85 54.6445C11.0225 55.2989 9.30777 55.7333 12.8479 57.1519C14.8356 57.9502 18.6229 69.7228 21.4241 67.9299C24.2253 66.1371 15.85 54.6445 15.85 54.6445ZM13.5637 29.4819C8.53537 29.4819 7.62909 37.763 11.8541 38.8885C11.8541 38.8885 8.33455 47.5779 16.8824 47.5779C15.4741 43.1808 12.8608 36.0252 14.8716 30.8115L13.5637 29.4819ZM64.7685 29.4819C69.7968 29.4819 70.7031 37.763 66.4781 38.8885C66.4781 38.8885 69.9976 47.5779 61.4498 47.5779C62.8581 43.1808 65.4714 36.0252 63.4606 30.8115"
                fill="#292F33"
              />
              <path
                d="M39.1743 73.8946C53.3938 73.8946 64.9209 59.2471 64.9209 41.1785C64.9209 23.1099 53.3938 8.4624 39.1743 8.4624C24.9549 8.4624 13.4277 23.1099 13.4277 41.1785C13.4277 59.2471 24.9549 73.8946 39.1743 73.8946Z"
                fill="#F7DECE"
              />
              <path
                d="M28.8696 45.1048C27.4484 45.1048 26.2949 43.9323 26.2949 42.4875V39.8702C26.2949 38.4255 27.4484 37.2529 28.8696 37.2529C30.2908 37.2529 31.4442 38.4255 31.4442 39.8702V42.4875C31.4442 43.9323 30.2908 45.1048 28.8696 45.1048ZM49.4669 45.1048C48.0431 45.1048 46.8922 43.9323 46.8922 42.4875V39.8702C46.8922 38.4255 48.0431 37.2529 49.4669 37.2529C50.8906 37.2529 52.0415 38.4255 52.0415 39.8702V42.4875C52.0415 43.9323 50.8906 45.1048 49.4669 45.1048Z"
                fill="#662113"
              />
              <path
                d="M41.746 54.2662C41.746 54.9885 41.1693 55.5748 40.4587 55.5748H37.884C37.1734 55.5748 36.5967 54.9885 36.5967 54.2662C36.5967 53.5438 37.1734 52.9575 37.884 52.9575H40.4587C41.1693 52.9575 41.746 53.5438 41.746 54.2662Z"
                fill="#C1694F"
              />
              <path
                className="lunette-anim"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.5403 39.3157C12.9214 39.7004 14.8266 40.8625 15.2077 42.0219C15.5887 43.1814 16.3534 49.7534 18.6397 51.6876C20.9981 53.682 29.891 52.9386 31.5954 52.0776C35.4059 50.146 35.8745 44.7544 36.549 42.0219C36.9301 40.4751 39.2164 40.4751 39.2164 40.4751C39.2164 40.4751 41.5027 40.4751 41.8837 42.0219C42.5583 44.7544 43.0269 50.146 46.8374 52.0749C48.5418 52.9386 57.4347 53.682 59.7982 51.6902C62.0819 49.756 62.844 43.184 63.2251 42.0219C63.601 40.8625 65.5114 39.703 65.8899 39.3157C66.2709 38.9283 66.2709 37.3815 65.8899 36.9941C65.1278 36.222 56.1371 35.5311 46.4563 36.6068C44.5253 36.8214 43.7916 37.3789 39.2164 37.3789C34.6438 37.3789 33.9074 36.8188 31.9765 36.6068C22.2983 35.5311 13.305 36.2194 12.5429 36.9941C12.1593 37.3815 12.1593 38.9309 12.5403 39.3157Z"
                fill="#292F33"
              />
              <path
                d="M13.0621 22.5826C13.0621 22.5826 16.5611 -0.713861 28.335 0.670685C37.3772 1.7333 40.9251 2.82995 48.5023 0.924562C53.5151 -0.336971 58.9683 3.8978 62.2561 12.3857C64.357 17.7982 65.4075 22.9621 65.4075 22.9621C65.4075 22.9621 75.5465 21.9545 76.131 27.4953C76.718 33.0361 64.9209 39.8384 35.2222 39.8384C18.577 39.8358 1.40403 33.6669 0.587867 28.5029C-0.228301 23.339 13.0621 22.5826 13.0621 22.5826Z"
                fill="#66757F"
              />
              <path
                d="M13.7476 19.3255C13.7476 19.3255 13.0524 22.2149 12.7023 24.2224C12.3521 26.2325 13.2841 27.6144 19.6667 28.9963C28.1296 30.8284 47.1615 30.8075 58.4359 27.9939C62.9648 26.8633 65.8664 26.1095 65.5188 23.8508C65.0889 21.0555 65.0142 21.0607 64.1259 18.0718C64.1259 18.0718 60.3232 19.3857 55.3026 20.5844C48.454 22.2176 29.6487 22.9687 20.5936 20.9613C15.7687 19.8882 13.7476 19.3255 13.7476 19.3255ZM13.3974 55.8445C12.486 56.224 20.918 59.4511 32.6559 59.6605C44.9396 59.8804 48.7063 60.6812 45.9051 63.4791C42.9545 66.4236 43.7115 71.0536 43.92 72.425C44.1286 73.7965 49.1903 77.4397 49.6049 79.1253C50.0194 80.8134 46.4406 94.7269 46.4406 94.7269L23.9664 94.0674C17.3624 70.9201 16.5437 71.8178 13.3974 58.4618C12.7769 55.8236 13.3974 55.8445 13.3974 55.8445Z"
                fill="#292F33"
              />
              <path
                d="M10.8224 55.9502C9.91101 56.3323 18.343 59.5568 30.0809 59.7662C42.3646 59.9861 46.1313 60.787 43.3301 63.5848C40.3795 66.5293 41.6205 71.1593 41.8291 72.5307C42.0376 73.9022 46.6154 77.5455 47.0324 79.2336C47.4444 80.9191 43.863 94.8326 43.863 94.8326L21.3888 94.1731C14.7848 71.0258 13.9661 71.9235 10.8199 58.5675C10.2019 55.9293 10.8224 55.9502 10.8224 55.9502Z"
                fill="#66757F"
              />
              <path
                d="M65.0537 55.8418C65.9651 56.2213 57.5331 59.4484 45.7978 59.6578C33.5141 59.8777 29.7474 60.6786 32.5486 63.4764C35.4992 66.4209 40.2108 71.0456 40.0048 72.4171C39.7963 73.7885 29.2634 77.437 28.8488 79.1226C28.4343 80.8107 32.0131 94.7242 32.0131 94.7242L54.4873 94.0647C61.0913 70.9174 61.91 71.8151 65.0537 58.4591C65.6742 55.8209 65.0537 55.8418 65.0537 55.8418Z"
                fill="#292F33"
              />
              <path
                d="M67.3799 55.8418C68.2913 56.2213 59.8593 59.4484 48.124 59.6578C35.8403 59.8777 32.0736 60.6786 34.8748 63.4764C37.8254 66.4209 42.5396 71.0456 42.331 72.4171C42.1225 73.7886 31.5895 77.437 31.175 79.1226C30.7605 80.8107 34.3393 94.7242 34.3393 94.7242L56.6281 94.7504C63.2321 71.6031 64.2362 71.8151 67.3825 58.4591C68.0004 55.8209 67.3799 55.8418 67.3799 55.8418Z"
                fill="#66757F"
              />
            </SvgContainer>
            <Name>{prismic.talents[11].name[0].text}</Name>
            <Post>{prismic.talents[11].role[0].text}</Post>
          </MemberCadre>
          <MemberCadre
            className="click"
            onClick={e => isMobile && e.preventDefault()}
            href={
              isMobile
                ? "#"
                : `mailto:${prismic.talents[12].name[0].text}@novlini.com`
            }
            data-mail={prismic.talents[12].name[0].text}
          >
            <ImageMember>
              <Img
                loading="eager"
                fixed={[
                  data.julietteMobile.childImageSharp.fixed,
                  {
                    ...data.juliette.childImageSharp.fixed,
                    media: `(min-width: 768px)`,
                  },
                ]}
              />
            </ImageMember>
            <Name>{prismic.talents[12].name[0].text}</Name>
            <Post>{prismic.talents[12].role[0].text}</Post>
          </MemberCadre>
          {!isMobile && (
            <MemberCadre
              className="click"
              onClick={e => isMobile && e.preventDefault()}
              href={
                isMobile
                  ? "#"
                  : `mailto:${prismic.talents[13].name[0].text}@novlini.com`
              }
              data-mail={prismic.talents[13].name[0].text}
            >
              <ImageMember>
                <Img
                  loading="eager"
                  fixed={data.gilbert.childImageSharp.fixed}
                />
              </ImageMember>
              <Name>{prismic.talents[13].name[0].text}</Name>
              <Post>{prismic.talents[13].role[0].text}</Post>
            </MemberCadre>
          )}
          <MemberCadre
            className="click"
            onClick={e => isMobile && e.preventDefault()}
            href={
              isMobile
                ? "#"
                : `mailto:${prismic.talents[14].name[0].text}@novlini.com`
            }
            data-mail={prismic.talents[14].name[0].text}
          >
            <ImageMember>
              <Img
                loading="eager"
                fixed={[
                  data.benedicteMobile.childImageSharp.fixed,
                  {
                    ...data.benedicte.childImageSharp.fixed,
                    media: `(min-width: 768px)`,
                  },
                ]}
              />
            </ImageMember>
            <Name>{prismic.talents[14].name[0].text}</Name>
            <Post>{prismic.talents[14].role[0].text}</Post>
          </MemberCadre>
          <MemberCadre
            className="click"
            onClick={e => isMobile && e.preventDefault()}
            href={
              isMobile
                ? "#"
                : `mailto:${prismic.talents[15].name[0].text}@novlini.com`
            }
            data-mail={prismic.talents[15].name[0].text}
          >
            <ImageMember>
              <Img
                loading="eager"
                fixed={[
                  data.pierreMobile.childImageSharp.fixed,
                  {
                    ...data.pierre.childImageSharp.fixed,
                    media: `(min-width: 768px)`,
                  },
                ]}
              />
            </ImageMember>
            <Name>{prismic.talents[15].name[0].text}</Name>
            <Post>{prismic.talents[15].role[0].text}</Post>
          </MemberCadre>
          <AbsoluteText>
            {isMobile ? "Rencontrons-nous !" : "Rencontrons-nous"}
          </AbsoluteText>
          <ButtonContainer>
            <LinkBack
              href="https://www.linkedin.com/company/novlini"
              target="_blank"
              className="click"
              background="#FFF"
              noBackground
            >
              découvrir l'ambiance
            </LinkBack>
          </ButtonContainer>
        </TeamContainer>
        <FixedContainer id="fixedDiv">
          <ContainerAnimScroll
            id="talentsSticky"
            className="changeHeaderString"
          >
            <WorkTitleContainer paddingTop>
              {isMobile && (
                <SectionTitle>
                  02.
                  <br />
                  {prismic.section_title_mobile_1[0].text}
                </SectionTitle>
              )}
              <Title>
                {prismic.second_part_title_black[0].text}{" "}
                <BlueText>{prismic.second_part_title_blue[0].text}</BlueText>{" "}
                {prismic.second_part_title_black_1[0].text}
              </Title>
              <Text style={{ marginTop: "-10px", lineHeight: "1rem" }}>
                {prismic.second_part_text.map((subtitle, index) => (
                  <span key={index}>
                    {subtitle.text}
                    {isMobile && index === 0 && "."}
                    {!isMobile && index === 0 && (
                      <>
                        ;<br />
                      </>
                    )}
                  </span>
                ))}
              </Text>
            </WorkTitleContainer>
            <WorkContainer>
              {isMobile ? (
                <>
                  <svg
                    className="bulle-mobile-droite"
                    style={{ position: "absolute", right: "0", top: "28%" }}
                    width="326"
                    height="384"
                    viewBox="0 0 326 384"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0.676411 196.278C-1.9291 157.356 2.06106 113.691 29.2672 85.7593C55.6424 58.6808 100.954 71.0987 136.107 57.2363C175.004 41.8974 203.591 -6.82874 244.696 0.806122C285.407 8.36758 296.856 61.221 324.359 92.2017C356.152 128.015 410.012 149.079 418.222 196.278C426.662 244.79 404.37 299.917 365.813 330.501C329.152 359.58 275.533 336.074 229.767 345.76C191.357 353.889 158.801 392.371 120.841 382.346C82.6668 372.264 64.8099 329.723 43.377 296.536C23.179 265.26 3.1638 233.435 0.676411 196.278Z"
                      fill="#F5FAFF"
                    />
                  </svg>
                  <svg
                    className="bulle-mobile-gauche"
                    style={{ position: "absolute", left: "0", top: "75%" }}
                    width="157"
                    height="190"
                    viewBox="0 0 157 190"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M-64.9737 96.2172C-66.1367 51.694 -28.5182 17.9423 13.9888 4.80159C54.4126 -7.6951 97.8111 4.51013 123.953 37.812C153.749 75.768 171.334 129.444 141.777 167.587C113.871 203.598 61.4478 188.871 18.4086 174.014C-21.3011 160.307 -63.8757 138.255 -64.9737 96.2172Z"
                      fill="#F5FAFF"
                    />
                  </svg>
                </>
              ) : (
                <BackgroundBlob
                  style={{ width: "65%", left: "-10%", top: "0" }}
                />
              )}
              {!isMobile && (
                <BigContainer>
                  <BigTitle>
                    Travailler <br /> pour <br /> novlini
                  </BigTitle>
                  <BigButton
                    href="https://talents-novlini.typeform.com/to/tLfut5Z3"
                    target="_blank"
                    className="click"
                  >
                    rejoins-nous !
                  </BigButton>
                </BigContainer>
              )}
              <CardsContainer>
                <CardInner id="cardInner">
                  {prismic.requirements.map((requirement, index) => (
                    <Card
                      key={index}
                      index={index + 1}
                      title={
                        isMobile &&
                        requirement.requirement_title_mobile[0] &&
                        requirement.requirement_title_mobile[0].text
                          ? requirement.requirement_title_mobile[0].text
                          : requirement.requirement_title_pc[0].text
                      }
                      text={requirement.requirement_text[0].text}
                    />
                  ))}
                </CardInner>
              </CardsContainer>
              {isMobile && (
                <BigButton className="join">rejoins-nous !</BigButton>
              )}
            </WorkContainer>
          </ContainerAnimScroll>
        </FixedContainer>
      </MainContainer>
    </Layout>
  )
}

export default PageTalents

export const query = graphql`
  query {
    maxime: file(relativePath: { eq: "maxime.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(height: 70) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    maximeMobile: file(relativePath: { eq: "maxime.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(height: 60) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    pernilla: file(relativePath: { eq: "pernilla.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(height: 70) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    pernillaMobile: file(relativePath: { eq: "pernilla.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(height: 60) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    vincent: file(relativePath: { eq: "vincent.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(height: 70) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    vincentMobile: file(relativePath: { eq: "vincent.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(height: 60) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    regis: file(relativePath: { eq: "regis.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(height: 70) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    regisMobile: file(relativePath: { eq: "regis.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(height: 60) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    hamza: file(relativePath: { eq: "hamza.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(height: 70) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    hamzaMobile: file(relativePath: { eq: "hamza.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(height: 60) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    vanille: file(relativePath: { eq: "vanille.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(height: 70) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    vanilleMobile: file(relativePath: { eq: "vanille.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(height: 60) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    marguerite: file(relativePath: { eq: "marguerite.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(height: 70) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    margueriteMobile: file(relativePath: { eq: "marguerite.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(height: 60) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    clotaire: file(relativePath: { eq: "clotaire.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(height: 70) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    clotaireMobile: file(relativePath: { eq: "clotaire.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(height: 60) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    emma: file(relativePath: { eq: "emma.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(height: 70) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    emmaMobile: file(relativePath: { eq: "emma.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(height: 60) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    wissal: file(relativePath: { eq: "wissal.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(height: 70) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    wissalMobile: file(relativePath: { eq: "wissal.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(height: 60) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    juliette: file(relativePath: { eq: "juliette.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(height: 70) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    julietteMobile: file(relativePath: { eq: "juliette.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(height: 60) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    gilbert: file(relativePath: { eq: "gilbert.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(height: 70) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    benedicte: file(relativePath: { eq: "benedicte.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(height: 70) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    benedicteMobile: file(relativePath: { eq: "benedicte.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(height: 60) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    pierre: file(relativePath: { eq: "pierre.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(height: 70) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    pierreMobile: file(relativePath: { eq: "pierre.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(height: 60) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    prismicPageTalents {
      data {
        page_title {
          text
        }
        page_description {
          text
        }
        og_image {
          url
        }
        first_part_text {
          text
        }
        first_part_title_black {
          text
        }
        first_part_title_black_1 {
          text
        }
        first_part_title_blue {
          text
        }
        footer_quote {
          text
        }
        mobile_section_title {
          text
        }
        requirements {
          requirement_text {
            text
          }
          requirement_title_mobile {
            text
          }
          requirement_title_pc {
            text
          }
        }
        second_part_text {
          text
        }
        second_part_title_black {
          text
        }
        second_part_title_black_1 {
          text
        }
        second_part_title_blue {
          text
        }
        section_title_mobile_1 {
          text
        }
        talents {
          name {
            text
          }
          role {
            text
          }
        }
      }
    }
  }
`
