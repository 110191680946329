import React from "react"
import styled, { keyframes } from "styled-components"

const moveBlob = keyframes`
    0% { transform: translate(0, 0) scale(1) rotate(0); }
    33% { transform: translate(10%, -5%) scale(1.1) rotate(-45deg); }
    66% { transform: translate(5%, -10%) scale(0.9) rotate(15deg); }
    100% { transform: translate(0, 0) scale(1) rotate(0); }
`

const Blob = styled.svg`
  position: absolute;
  animation: ${moveBlob} 8s infinite;
  z-index: 0;
  transform: scale(1.5);
  @media only screen and (max-width: 800px) {
    right: 0;
    top: 20px;
    width: 270%;
    transform: scale(0.8);
    ${props => props.little && "width: 50%;"}
  }
`

const Container = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vw;
  top: 0;
  left: 0;
  overflow: hidden;
`

const BackgroundBlob = props => {
  return (
    <Container>
      <Blob {...props} viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
        <path
          fill={props.green ? "#D0D5A220" : "#F5FAFF"}
          d="M48.3,-46.6C62.6,-33.9,74.4,-17,73.3,-1.1C72.2,14.8,58.3,29.6,43.9,38.9C29.6,48.2,14.8,52,-1.3,53.3C-17.4,54.6,-34.8,53.4,-44.9,44.1C-55.1,34.8,-58.1,17.4,-56,2.2C-53.8,-13.1,-46.5,-26.1,-36.3,-38.8C-26.1,-51.5,-13.1,-63.8,2,-65.8C17,-67.8,33.9,-59.3,48.3,-46.6Z"
          transform="translate(100 100)"
        />
      </Blob>
    </Container>
  )
}

export default BackgroundBlob
